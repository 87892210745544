import { EventEmitter } from '@lib/events/event-emitter';

import { type RoboClient } from '../robo-client';
import { type Command } from '../types';

import { TransactionCommandsQueue, TransactionEvents, TransactionId } from './types';

export enum TransactionStatus {
  Started = 'started',
  Finished = 'finished',
}

export class TransactionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TransactionError';
  }
}

/**
 * Represents a transaction that can hold and execute multiple commands.
 * A transaction maintains a queue of commands that can be executed together.
 * Once a transaction is finished/executed, no new commands can be added to it.
 *
 * Events:
 * - 'started': Emitted when the transaction starts
 * - 'finished': Emitted when the transaction finishes executing all commands
 * - 'command': Emitted for each command execution
 */

export class Transaction extends EventEmitter<TransactionEvents> {
  private commandsQueue: TransactionCommandsQueue = [];
  private client: RoboClient;
  private status: TransactionStatus;
  private _name: TransactionId;

  constructor(client: RoboClient, name: TransactionId) {
    super();
    this.client = client;
    this._name = name;
    this.status = TransactionStatus.Started;
  }

  /**
   * Gets the name of this transaction
   */
  get name(): TransactionId {
    return this._name;
  }

  /**
   * Adds a command to the transaction queue.
   * If the transaction is already finished, throws a TransactionError.
   * @throws {TransactionError} If the transaction is already finished.
   */
  addCommand(command: Command, payload: Uint8Array) {
    if (this.status === TransactionStatus.Finished) {
      throw new TransactionError('Transaction is executed, it is not possible to add new commands to it');
    }
    this.commandsQueue.push({
      command,
      payload,
    });
  }

  /**
   * Gets the transaction status.
   */
  getTransactionStatus(): TransactionStatus {
    return this.status;
  }

  /**
   * Executes the transaction by processing all commands in the queue.
   * After execution, the transaction is marked as finished and cannot accept new commands.
   */
  execute() {
    // !fixme Remove after stabilizing
    console.debug(`Executing transaction "${this.name}"`, this.commandsQueue);

    if (this.status === TransactionStatus.Finished) {
      throw new TransactionError('Transaction is executed, it is not possible to execute it again');
    }

    if (!this.hasCommands()) {
      console.debug(`Executing empty transaction "${this.name}"`);
    }

    // if client supports multiple commands - build multiple commands payload
    if (this.client.supportsMultipleCommands()) {
      console.log('Sending multiple commands');
      const payloads = this.client.buildMultipleCommands(this.commandsQueue);
      payloads.forEach(payload => {
        this.client.send(payload);
      });
    } else {
      // if client does not support multiple commands - send commands one by one
      this.commandsQueue.forEach(command => {
        this.client.sendCommandThroughQueue(command.command, command.payload);
      });
    }

    this.commandsQueue = [];
    this.status = TransactionStatus.Finished;
    this.emit('executed', null);
  }

  /**
   * Gets the number of commands in the queue.
   */
  get size(): number {
    return this.commandsQueue.length;
  }

  /**
   * Checks if the transaction has any commands in the queue.
   */
  hasCommands(): boolean {
    return this.commandsQueue.length > 0;
  }

  /**
   * Gets a copy of the commands queue.
   */
  getCommands(): TransactionCommandsQueue {
    return [...this.commandsQueue];
  }
}
