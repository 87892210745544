import { gql } from '@lib/store/strapi-graphql-query';

export const USER_LOGIN_MUTATION = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt

      user {
        id
        email
        username

        role {
          name
          type
        }

        blocked
        confirmed
      }
    }
  }
`;
export const USER_TEST_LOGIN_MUTATION = gql`
  mutation TestLogin($type: String!, $password: String!) {
    testLogin(input: { type: $type, password: $password }) {
      jwt

      user {
        id
        email
        username

        role {
          name
          type
        }
        confirmed
        blocked
      }
    }
  }
`;

export const USER_TEACHER_CODE_LOGIN_MUTATION = gql`
  mutation TeacherCodeLogin($code: String!) {
    teacherCodeLogin(code: $code) {
      jwt

      user {
        id
        email
        username

        role {
          name
          type
        }
        confirmed
        blocked
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      jwt
      user {
        id
        username
        email
        role {
          name
        }
      }
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
    changePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UsersPermissionsUserInput!, $id: ID!) {
    updateUsersPermissionsUser(data: $data, id: $id) {
      data {
        id
        attributes {
          username
          email
          role {
            data {
              attributes {
                name
                type
              }
            }
          }
        }
      }
    }
  }
`;

export const REGENERATE_TEACHER_CODE_MUTATION = gql`
  mutation RegenerateTeacherCode {
    regenerateTeacherCode {
      code
    }
  }
`;

export const MY_TEACHER_CODE_QUERY = gql`
  query MyTeacherCode {
    myTeacherCode {
      code
      teacher {
        id
        username
      }
    }
  }
`;
