import { Transaction } from './transaction';

import { type RoboClient } from '../robo-client';
import { type TransactionId } from './types';

export class TransactionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TransactionError';
  }
}

export const DEFAULT_TRANSACTION_NAME = 'default';

/**
 * TransactionsManager handles the lifecycle and management of multiple transactions in the Robo system.
 * It supports nested transactions, where you can have multiple active transactions in a stack.
 *
 * Example of nested transactions:
 * ```typescript
 * manager.beginTransaction('outer'); // outer transaction is active
 * manager.beginTransaction('inner'); // inner transaction is active, outer is in stack
 * manager.executeTransaction('inner'); // inner transaction is removed, outer becomes active again
 * manager.executeTransaction('outer'); // outer transaction is removed
 * ```
 *
 * When a nested transaction is executed or removed, the previous transaction in the stack
 * becomes active automatically. This allows for proper handling of nested operations
 * while maintaining transaction context.
 */
export class TransactionsManager {
  private client: RoboClient;
  private transactions: Map<TransactionId, Transaction> = new Map();
  private activeTransaction: Transaction | null = null;
  private transactionStack: Transaction[] = []; // Stack to track nested transactions

  constructor(client: RoboClient) {
    this.client = client;
  }

  /**
   * Gets the name of the currently active transaction, if any.
   */
  getActiveTransactionName(): TransactionId | null {
    return this.activeTransaction?.name ?? null;
  }

  /**
   * Gets the currently active transaction.
   */
  getActiveTransaction(): Transaction | null {
    return this.activeTransaction;
  }

  /**
   * Validates the transaction name.
   * @throws {TransactionError} If transaction name is empty
   */
  private validateTransactionName(name: TransactionId): void {
    if (!name.trim()) {
      throw new TransactionError('Transaction name cannot be empty');
    }
  }

  /**
   * Gets a transaction by its name.
   * If no name is provided, returns the default transaction.
   * @throws {TransactionError} If transaction name is empty
   */
  getTransaction(name: TransactionId = DEFAULT_TRANSACTION_NAME): Transaction | null {
    this.validateTransactionName(name);
    return this.transactions.get(name) ?? null;
  }

  /**
   * Creates a new transaction with the given name.
   * If no name is provided, creates a default transaction.
   * @throws {TransactionError} If transaction with this name already exists or name is empty
   */
  createTransaction(name: TransactionId = DEFAULT_TRANSACTION_NAME): Transaction {
    this.validateTransactionName(name);

    if (this.transactions.has(name)) {
      throw new TransactionError(`Transaction with name "${name}" already exists`);
    }

    const transaction = new Transaction(this.client, name);
    transaction.on('executed', () => {
      this.transactions.delete(name);
      if (this.activeTransaction === transaction) {
        // Restore the previous transaction from the stack
        this.activeTransaction = this.transactionStack.pop() ?? null;
      }
    });

    this.transactions.set(name, transaction);
    return transaction;
  }

  /**
   * Begins a new transaction or returns an existing one with the given name.
   * Makes the transaction active.
   * @throws {TransactionError} If transaction name is empty or transaction already exists
   */
  beginTransaction(name: TransactionId = DEFAULT_TRANSACTION_NAME): Transaction {
    this.validateTransactionName(name);

    if (this.transactions.has(name)) {
      throw new TransactionError(`Transaction with name "${name}" already exists`);
    }

    const transaction = this.createTransaction(name);

    if (this.activeTransaction) {
      console.debug(`Switching active transaction from "${this.activeTransaction.name}" to "${name}"`);
      // Push the current transaction onto the stack
      this.transactionStack.push(this.activeTransaction);
    }

    this.activeTransaction = transaction;
    return transaction;
  }

  /**
   * Executes a transaction with the given name and removes it from the manager.
   * If no name is provided, executes the default transaction.
   * @throws {TransactionError} If transaction name is empty
   * @returns true if the transaction was found and executed, false otherwise
   */
  executeTransaction(name: TransactionId = DEFAULT_TRANSACTION_NAME): boolean {
    this.validateTransactionName(name);

    const transaction = this.transactions.get(name);
    if (!transaction) {
      return false;
    }

    transaction.execute();

    // If this was the last transaction, clean up the stack
    if (this.transactions.size === 0) {
      this.transactionStack = [];
    }

    return true;
  }

  /**
   * Executes all pending transactions.
   * @returns Number of transactions executed
   */
  executeAllTransactions(): number {
    const count = this.transactions.size;
    if (count === 0) {
      console.warn('No transactions to execute');
      return 0;
    }

    for (const transaction of this.transactions.values()) {
      transaction.execute();
    }

    return count;
  }

  /**
   * Removes a transaction without executing it.
   * If no name is provided, removes the default transaction.
   * @throws {TransactionError} If transaction name is empty
   * @returns true if the transaction was found and removed, false otherwise
   */
  removeTransaction(name: TransactionId = DEFAULT_TRANSACTION_NAME): boolean {
    this.validateTransactionName(name);

    const transaction = this.transactions.get(name);
    if (!transaction) {
      return false;
    }

    if (transaction.hasCommands()) {
      console.warn(`Removing transaction "${name}" with pending commands`);
    }

    // Clean up the transaction stack
    const stackIndex = this.transactionStack.indexOf(transaction);
    if (stackIndex !== -1) {
      this.transactionStack.splice(stackIndex, 1);
    }

    if (this.activeTransaction === transaction) {
      this.activeTransaction = this.transactionStack.pop() ?? null;
    }

    return this.transactions.delete(name);
  }

  /**
   * Removes all transactions without executing them.
   * @returns Number of transactions removed
   */
  clear(): number {
    const count = this.transactions.size;
    this.transactions.clear();
    this.activeTransaction = null;
    this.transactionStack = [];
    return count;
  }

  /**
   * Checks if a transaction with the given name exists.
   * If no name is provided, checks for the default transaction.
   * @throws {TransactionError} If transaction name is empty
   */
  hasTransaction(name: TransactionId = DEFAULT_TRANSACTION_NAME): boolean {
    this.validateTransactionName(name);
    return this.transactions.has(name);
  }

  /**
   * Gets the number of pending transactions.
   */
  getPendingTransactionsCount(): number {
    return this.transactions.size;
  }
}
