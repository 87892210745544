export enum HandlerScope {
  BROADCAST = 'broadcast',
  TARGET = 'target',
}

export enum HandlerType {
  OnRoboConfiguration = 'onRoboConfiguration',
  OnFirmwareUpdate = 'onFirmwareUpdate',
  OnFirmwareVersion = 'onFirmwareVersion',
  OnBattery = 'onBattery',
  OnBatteryAlert = 'onBatteryAlert',
  OnSoundLoudness = 'onSoundLoudness',
  OnWiFiScan = 'onWiFiScan',
  OnRunProgram = 'onRunProgram',
  OnModuleUpdate = 'onModuleUpdate',
  OnLedRGB = 'onLedRGB',
  OnSounds = 'onSounds',
  OnSound = 'onSound',
  OnLightLevel = 'onLightLevel',
  OnSoundLevel = 'onSoundLevel',
  OnMotionDetector = 'onMotionDetector',
  OnProximity = 'onProximity',
  OnButton = 'onButton',
  OnLinetracker = 'onLinetracker',
  OnAccelerometerState = 'onAccelerometerState',
  OnAccelerometer = 'onAccelerometer',
  OnGyro = 'onGyro',
  OnBatchSensors = 'onBatchSensors',
  OnActionOrTriggerResponse = 'onActionOrTriggerResponse',
  OnSensor = 'onSensor',

  // Trigger Handlers
  OnButtonTrigger = 'onButtonTrigger',
}
