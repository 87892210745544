import { debounce } from 'lodash';

import { BaseModule, type IRoboStore, type onActionDoneType } from './base-module';

import { mapToRange } from '@lib/utils/math';

import { type RoboClient } from '@lib/robo/robo-client';
import { type ModuleId, ModulesCollectionTypes } from '@lib/robo/types';

export enum ROTATION_DIRECTIONS {
  cw = 0,
  ccw = 1,
}

export enum MOTOR_PLACEMENT {
  left = 'left',
  right = 'right',
}

export type MotorsSpeedConfig = {
  [key: ModuleId]: {
    rotationDirection: ROTATION_DIRECTIONS;
    speed: number;
  };
};

/**
 * Motor module class that extends BaseModule to control motor functionality.
 * Handles motor speed, direction, and angle control.
 *
 * Features:
 * - Speed control with debounced updates
 * - Rotation direction control (clockwise/counter-clockwise)
 * - Angle positioning
 * - Speed value conversion between percentage and hex formats
 *
 * @extends BaseModule
 */

export class Motor extends BaseModule<typeof Motor> {
  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.Motor, store);
  }

  /**
   * Sets the speed of the motor with debounce functionality.
   * @param {number} speed - The speed value to set.
   * @param {number} rotationDirection - The rotation direction.
   */
  setSpeedDebounced = debounce(this.setSpeed, 50);

  /**
   * Converts the speed value to hex.
   *
   * @param {number} speed - The speed value to convert [-100, 100].
   * @param {ROTATION_DIRECTIONS} rotationDirection - The rotation direction.
   * @returns {number} The hex value [1 - 254]
   */
  convertSpeedToHex(speed: number, rotationDirection: ROTATION_DIRECTIONS): number {
    if (speed === 0) {
      return 0x00;
    }

    if (rotationDirection === ROTATION_DIRECTIONS.cw) {
      return speed > 0
        ? mapToRange(speed, [0, 100], [0x01, 0x64]) // 1-100
        : mapToRange(speed, [0, -100], [0xfe, 0x9c]); // 254-156
    } else {
      return speed > 0
        ? mapToRange(speed, [0, 100], [0xfe, 0x9c]) // 254-156
        : mapToRange(speed, [0, -100], [0x01, 0x64]); // 1-100
    }
  }

  /**
   * Sets the angle of the motor.
   * @param {number} angle - The angle to set.
   * @param {string} [rotationDirection=ROTATION_DIRECTIONS.cw] - The direction of rotation (default: clockwise).
   */
  angle(angle: number, rotationDirection: ROTATION_DIRECTIONS = ROTATION_DIRECTIONS.cw) {
    this.client.motorAngleAction(1, this.index, angle, !!rotationDirection);
  }

  /**
   * Sets the speed of the motor.
   * @param {number} speed - The speed value.
   * @param {ROTATION_DIRECTIONS} [rotationDirection=ROTATION_DIRECTIONS.cw] - The rotation direction. Defaults to clockwise.
   */
  setSpeed(speed: number, rotationDirection: ROTATION_DIRECTIONS = ROTATION_DIRECTIONS.cw) {
    const speedHexValue = this.convertSpeedToHex(speed, rotationDirection);
    this.client.setMotor(this.index, speedHexValue);
  }

  /**
   * Sets the motor action.
   * @param speed - The speed value to set.
   * @param distance - The distance to set.
   * @param onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setMotorAction(speed: number, distance: number, onActionDone: onActionDoneType) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.setMotorAction(actionId, this.index, speed, 0x5a /** wheel diameter */, distance);

    return { actionId };
  }

  /**
   * Sets the angle of the motor.
   * @param angle - The angle to set.
   * @param rotationDirection - The rotation direction. Default is 'cw'.
   * @param onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setAngleAction(angle: number, rotationDirection: ROTATION_DIRECTIONS, onActionDone: onActionDoneType) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.motorAngleAction(actionId, this.index, angle, !!rotationDirection);

    return { actionId };
  }

  /**
   * Sets the drive action.
   * @param {number} motorsMap - The motors bitfield. LSB is the first motor.
   * @param {number} motorsDirectionsMap - The motors directions bitfield. LSB is the first motor. 0 - ccw, 1 - cw.
   * @param {number} speed - The speed value to set.
   * @param {number} distance - The distance to travel.
   * @param {onActionDoneType} onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setDriveAction(
    motorsMap: number,
    motorsDirectionsMap: number,
    speed: number,
    distance: number,
    onActionDone: onActionDoneType
  ) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.setDriveAction(actionId, motorsMap, motorsDirectionsMap, speed, 0x5a /** wheel diameter */, distance);

    return { actionId };
  }

  /**
   * Sets the drive action with steering.
   * @param {number} motorsMap - The motors bitfield. LSB is the first motor.
   * @param {number} motorsDirectionsMap - The motors directions bitfield. LSB is the first motor. 0 - ccw, 1 - cw.
   * @param {number} speed - The speed value to set.
   * @param {number} distance - The distance to travel.
   * @param {number} steeringAngle - The steering angle (-100 to 100, where negative is left, positive is right).
   * @param {onActionDoneType} onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setDriveSteeringAction(
    motorsMap: number,
    motorsDirectionsMap: number,

    speed: number,
    distance: number,
    steeringAngle: number,
    onActionDone: onActionDoneType
  ) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.setDriveSteeringAction(
      actionId,
      motorsMap,
      motorsDirectionsMap,
      speed,
      0x5a /** wheel diameter */,
      distance,
      steeringAngle
    );

    return { actionId };
  }

  /**
   * Sets the turn action.
   * @param {number} motorsMap - The motors bitfield. LSB is the first motor.
   * @param {number} motorsDirectionsMap - The motors directions bitfield. LSB is the first motor. 0 - ccw, 1 - cw.
   * @param {number} speed - The speed value to set.
   * @param {number} angle - The angle to turn.
   * @param {onActionDoneType} onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setTurnAction(
    motorsMap: number,
    motorsDirectionsMap: number,
    speed: number,
    angle: number,
    onActionDone: onActionDoneType
  ) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.setTurnAction(actionId, motorsMap, motorsDirectionsMap, speed, 0x5a /** wheel diameter */, angle);

    return { actionId };
  }

  /**
   * Sets the gyro-assisted turn action.
   * @param {number} motorsMap - The motors bitfield. LSB is the first motor.
   * @param {number} motorsDirectionsMap - The motors directions bitfield. LSB is the first motor. 0 - ccw, 1 - cw.
   * @param {number} speed - The speed value to set.
   * @param {number} angle - The angle to turn.
   * @param {onActionDoneType} onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setGyroAssistedTurnAction(
    accelerometerModuleIndex: number,
    motorsMap: number,
    motorsDirectionsMap: number,
    speed: number,
    angle: number,
    onActionDone: onActionDoneType
  ) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.setGyroAssistedTurnAction(
      actionId,
      accelerometerModuleIndex,
      motorsMap,
      motorsDirectionsMap,
      speed,
      0x5a /** wheel diameter */,
      angle
    );

    return { actionId };
  }

  /**
   * Sets the speed for multiple motors simultaneously. It is used in the joystick widget.
   * @param {MotorsSpeedConfig} speedConfig - Configuration object mapping motor IDs to their speed and rotation settings
   * @param {{ [key: ModuleId]: Motor }} motors - Object mapping motor IDs to Motor instances
   */
  static setMultipleMotorsSpeed(speedConfig: MotorsSpeedConfig, motors: { [key: ModuleId]: Motor }) {
    Object.entries(speedConfig).forEach(([motorId, config]) => {
      const MOTOR = motors[motorId as ModuleId];
      const { speed, rotationDirection } = config;
      MOTOR?.setSpeed(speed, rotationDirection);
    });
  }
}
