// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventMap = Record<string, any>;

export type EventKey<T extends EventMap> = string & keyof T;
export type EventReceiver<T> = (params: T) => void;

export interface Emitter<T extends EventMap> {
  on<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>): () => void;

  off<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>): void;

  emit<K extends EventKey<T>>(eventName: K, params: T[K]): void;
}

export function createEventEmitter<T extends EventMap>(): Emitter<T> {
  const listeners: {
    [K in keyof EventMap]?: Array<(p: EventMap[K]) => void>;
  } = {};

  return {
    on(key, fn) {
      listeners[key] = (listeners[key] || []).concat(fn);

      return () => {
        listeners[key] = (listeners[key] || []).filter(f => f !== fn);
      };
    },
    off(key, fn) {
      listeners[key] = (listeners[key] || []).filter(f => f !== fn);
    },
    emit(key, data) {
      (listeners[key] || []).forEach(function (fn) {
        fn(data);
      });
    },
  };
}

export class EventEmitter<T extends EventMap> implements Emitter<T> {
  private emitter = createEventEmitter<T>();

  on<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>) {
    this.emitter.on(eventName, fn);

    return () => {
      this.emitter.off(eventName, fn);
    };
  }

  off<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>) {
    this.emitter.off(eventName, fn);
  }

  emit<K extends EventKey<T>>(eventName: K, params: T[K]) {
    this.emitter.emit(eventName, params);
  }
}
