import React, { useState, useContext, useMemo, useEffect, forwardRef, useRef } from 'react';
import { throttle } from 'lodash';

import { Typography, Slider, Avatar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { hslaToRgb } from '@lib/utils/color-utils';

import { colors as themeColors } from '@themes/config/theme-colors';
import { RgbLightIcon } from '@webapp/components/icons';
import StyledBox from '@webapp/components/blocks/component/styled-box';

import { EditorContext } from '@webapp/components/hoc/with-editor';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

const saturation = 100;
const lightness = 50;

const LedAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'status',
})(({ status }) => ({
  width: 'auto',
  height: 'calc(100% - 10px)',
  aspectRatio: '1 / 1',
  backgroundColor: status ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
  position: 'relative',
}));

const SliderThumb = forwardRef(({ value, thumbSize, children, ownerState: _, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        position: 'absolute',
        width: thumbSize,
        height: thumbSize,
        boxSizing: 'border-box',
        borderRadius: '50%',
        outline: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.50)',
      }}
      {...props}
    >
      <Box
        sx={{
          width: '40%',
          height: '40%',
          borderRadius: '50%',
          backgroundColor: `hsla(${value}, 100%, 50%, 4)`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
});

SliderThumb.displayName = 'SliderThumb';

const HueSlider = ({ value, thumbSize, onChange, disabled, sx }) => {
  return (
    <Slider
      value={value}
      onChange={onChange}
      min={0}
      max={360}
      step={1}
      aria-label="hue-slider"
      track={false}
      disabled={disabled}
      slots={{
        thumb: SliderThumb,
      }}
      slotProps={{
        thumb: {
          value,
          thumbSize,
        },
      }}
      sx={{
        width: '62%',
        borderRadius: 5,
        '& .MuiSlider-rail': {
          background: 'linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00)',
          boxShadow: '0 0 0 4px rgba(255, 255, 255, 1)',
          height: '8px',
          opacity: 1,
        },
        ...sx,
      }}
    />
  );
};

const RGBLightWidget = ({ counter, data, disabled }) => {
  const [hue, setHue] = useState(0);
  const [isLedOn, setIsLedOn] = useState(true);
  const { addHistoryEntry } = useActionsHistory();

  const { isPlaying, editorType } = useContext(EditorContext);
  const { model: roboModel } = useRobo();

  const moduleId = data.moduleIds[0];
  const LED_RGB = roboModel?.modules.ledRGBs[moduleId];

  const rootRef = useRef(null);
  const [thumbSize, setThumbSize] = useState(50);

  const updateLedStateDebounced = useMemo(() => {
    return throttle((isLedOn, hueValue) => {
      const RGBValue = hslaToRgb(hueValue, saturation, lightness);

      setIsLedOn(isLedOn);
      LED_RGB?.setActive(isLedOn);
      if (isLedOn) {
        LED_RGB?.setColor(RGBValue.red, RGBValue.green, RGBValue.blue);
      }
      LED_RGB?.execute();

      addHistoryEntry({
        action: 'interact:widget:light',
        scope: editorType,
        data: {
          isLedOn,
          color: RGBValue,
          moduleId,
        },
      });
    }, 300);
  }, [LED_RGB, addHistoryEntry, editorType, moduleId]);

  const handleHueChange = (event, newValue) => {
    updateLedStateDebounced(isLedOn, newValue);
    setHue(newValue);
  };

  const toggleLedState = () => {
    updateLedStateDebounced(!isLedOn, hue);
  };

  useEffect(() => {
    if (isPlaying) {
      if (isLedOn) {
        const RGBValue = hslaToRgb(hue, saturation, lightness);
        LED_RGB?.setActive(true);
        LED_RGB?.setColor(RGBValue.red, RGBValue.green, RGBValue.blue);
        LED_RGB?.execute();
      }
    } else {
      LED_RGB?.setActive(false);
      LED_RGB?.execute();
    }
  }, [isPlaying]);

  // set thumb size in px based on the width of the root element
  useEffect(() => {
    const resizeObserver = new ResizeObserver(elements => {
      const element = elements[0].target;
      setThumbSize(element.clientHeight - 20);
    });

    resizeObserver.observe(rootRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <StyledBox
      ref={rootRef}
      color={disabled ? WidgetColors.disabledBackground : '#FFB71C'}
      direction="row"
      paddingLeft="10px"
      paddingRight="48px"
      justify="space-between"
    >
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <LedAvatar status={isLedOn} onClick={isPlaying ? toggleLedState : null}>
          <RgbLightIcon
            sx={{
              width: '60%',
              height: '60%',
            }}
          />
        </LedAvatar>
        <Typography
          variant="x-headline5-semibold"
          color={themeColors.white['500']}
          sx={{
            height: '100%',
          }}
        >
          {counter}
        </Typography>
      </Box>
      <HueSlider
        value={hue}
        onChange={handleHueChange}
        disabled={!isPlaying || disabled}
        thumbSize={thumbSize}
        sx={{
          marginLeft: `${thumbSize / 2 + 20}px`,
        }}
      />
    </StyledBox>
  );
};

RGBLightWidget.initialProps = {
  width: 4,
  height: 1,
};

RGBLightWidget.initialData = {};

export default RGBLightWidget;
