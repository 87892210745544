import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../configureStore';

import { UserRole, School, District, Tag, Subscription } from '@store/types/common';
import {
  SchoolEntityResponseCollection,
  DistrictEntityResponse,
  TagEntityResponseCollection,
  SubscriptionEntityResponseCollection,
} from '@store/gql/graphql';

export enum LoginMethod {
  EMAIL = 'email',
  TEST_USER = 'test-user',
  TEACHER_CODE = 'teacher-code',
  CLASS_CODE = 'class-code',
  CLEVER = 'clever',
}

type AuthState = {
  isLoggedIn: boolean;
  user: User | null;
  jwt: string | null;
  loginMethod: LoginMethod | null;
};

type User = {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
  role?: UserRole;
  schools?: School[];
  district?: District;
  tags?: Tag[];
  subscriptions?: Subscription[];
  code?: string;
  isShared?: boolean;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    jwt: null,
    isLoggedIn: false,
    loginMethod: null,
  } as AuthState,

  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, jwt },
      }: PayloadAction<{
        user: User;
        jwt: string;
      }>
    ) => {
      state.user = user;
      state.jwt = jwt;
      state.isLoggedIn = true;
    },

    clearCredentials: state => {
      state.user = null;
      state.jwt = null;
      state.isLoggedIn = false;
      state.loginMethod = null;
    },

    setRole: (state, { payload: { role } }: PayloadAction<{ role: UserRole }>) => {
      if (state.user) {
        state.user.role = role;
      }
    },

    setSchools: (state, { payload: { schools } }: PayloadAction<{ schools: SchoolEntityResponseCollection }>) => {
      if (state.user) {
        state.user.schools = schools.data.map(school => ({
          id: school.id ?? '',
          name: school.attributes?.name ?? '',
        }));
      }
    },

    setDistrict: (state, { payload: { district } }: PayloadAction<{ district: DistrictEntityResponse }>) => {
      if (state.user) {
        state.user.district = {
          id: district.data?.id ?? '',
          name: district.data?.attributes?.name ?? '',
        };
      }
    },

    setTags: (state, { payload: { tags } }: PayloadAction<{ tags: TagEntityResponseCollection }>) => {
      if (state.user) {
        state.user.tags = tags.data.map(tag => ({
          id: tag.id ?? '',
          name: tag.attributes?.name ?? '',
        }));
      }
    },

    setSubscriptions: (
      state,
      { payload: { subscriptions } }: PayloadAction<{ subscriptions: SubscriptionEntityResponseCollection }>
    ) => {
      if (state.user) {
        state.user.subscriptions = subscriptions.data.map(subscription => ({
          id: subscription.id ?? '',
          name: subscription.attributes?.name ?? '',
        }));
      }
    },

    setCode: (state, { payload: { code } }: PayloadAction<{ code: string }>) => {
      if (state.user) {
        state.user.code = code;
      }
    },

    setIsShared: (state, { payload: { isShared } }: PayloadAction<{ isShared: boolean }>) => {
      if (state.user) {
        state.user.isShared = isShared;
      }
    },

    setLoginMethod: (state, { payload: { loginMethod } }: PayloadAction<{ loginMethod: LoginMethod }>) => {
      state.loginMethod = loginMethod;
    },
  },
});

export const {
  setCredentials,
  clearCredentials,
  setRole,
  setSchools,
  setDistrict,
  setTags,
  setSubscriptions,
  setCode,
  setIsShared,
  setLoginMethod,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState): User | object | null => state.auth.user;
export const selectToken = (state: RootState): string | null => state.auth.jwt;
export const selectAuth = (state: RootState): AuthState => state.auth;
