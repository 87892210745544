import React, { useState, useEffect, useRef, useCallback } from 'react';

import CircularSlider from '@fseehawer/react-circular-slider';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors as themeColors } from '@themes/config/theme-colors';
import { LiveAngleHandleIcon } from '@webapp/components/icons';
import StyledBox from '@webapp/components/blocks/component/styled-box';

import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '10px',
  left: '8px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}));

const strokeWidth = 4;

const MotorAngleWidget = ({ counter, data, disabled }) => {
  const [value, setValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [sliderKey, setSliderKey] = useState(0);
  const [size, setSize] = useState(null);
  const padding = 10;

  const rootRef = useRef();

  const { addHistoryEntry } = useActionsHistory();
  const { model: roboModel } = useRobo();
  const { isPlaying, editorType } = useEditorContext();

  const moduleId = data.moduleIds[0];
  const MOTOR = roboModel?.modules.motors[moduleId];

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const targetRect = entries?.[0]?.contentRect;
      if (!targetRect) {
        return;
      }

      const size = Math.min(targetRect.height, targetRect.width);
      setSize(size - padding);
      setValue(0);
      // to redraw
      setSliderKey(prev => prev + 1);
    });
    resizeObserver.observe(rootRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleDragChange = useCallback(
    isDragging => {
      setIsDragging(isDragging);
      if (MOTOR && !isDragging && value !== 0) {
        const positiveValue = Math.abs(value);
        const direction = value < 0 ? ROTATION_DIRECTIONS.ccw : ROTATION_DIRECTIONS.cw;
        MOTOR?.angle(positiveValue, direction);
        setValue(0);
        // to redraw
        setSliderKey(prev => prev + 1);

        addHistoryEntry({
          action: 'interact:widget:angle',
          scope: editorType,
          data: {
            speed: positiveValue,
            direction: direction,
            moduleId: MOTOR?.id,
          },
        });
      }
    },
    [value, MOTOR, editorType, addHistoryEntry]
  );

  const radius = size !== null ? size / 2 : 0;
  const normalizedRadius = radius - strokeWidth;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (value / 360) * circumference;

  return (
    <StyledBox color={disabled ? WidgetColors.disabledBackground : '#156BFB'} ref={rootRef}>
      {!!counter && <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography>}
      {size !== null && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              '& > div': {
                display: 'inline-flex !important',
              },
            }}
          >
            <CircularSlider
              // use key to fully redraw component with initial state
              // because it persists value and there is no way to reset it to 0
              // also redraw is needed when size is changed
              key={sliderKey}
              min={-360}
              max={360}
              hideKnob={true}
              hideLabelValue={true}
              progressSize={0}
              trackSize={0}
              preventDefault
              direction={1}
              knobPosition={'top'}
              initialValue={0}
              continuous={{
                enabled: true,
                clicks: 180,
                interval: 2,
              }}
              onChange={value => {
                setValue(value);
              }}
              isDragging={handleDragChange}
              width={size}
              trackDraggable={isPlaying && !disabled}
              knobDraggable={isPlaying && !disabled}
            />
          </Box>

          <LiveAngleHandleIcon
            sx={{
              width: '100%',
              height: '100%',
              color: 'gray',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: `translate(-50%, -50%) rotate(${value}deg)`,
            }}
          />

          {isDragging && (
            <svg
              height={size}
              width={size}
              style={{
                position: 'absolute',
                zIndex: 100,
                pointerEvents: 'none',
              }}
            >
              <circle
                stroke="white"
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeDasharray={`${circumference} ${circumference}`}
                strokeDashoffset={strokeDashoffset}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                transform={`rotate(-90, ${radius}, ${radius})`}
              />
            </svg>
          )}
        </>
      )}
    </StyledBox>
  );
};

MotorAngleWidget.initialProps = {
  width: 2,
  height: 2,
};

MotorAngleWidget.initialData = {};

export default MotorAngleWidget;
