import { RoboClient } from '../robo-client';
import { ModulesCollectionTypes } from '../types';
import { BaseModule, IRoboStore } from './base-module';

export class LedDisplay extends BaseModule<typeof LedDisplay> {
  // !fixme Extract these static properties to TS enum. Export only needed ones
  static DisplaySize = 16;

  static TextMaxLength = 255;

  static Image = {
    Custom: 0xff,
    SmilingFace: 0x01,
    LaughingFace: 0x02,
  };

  static Animations = {
    LaughingFace: 0x00,
    SmilingFace: 0x01,
    LookingFace: 0x02,
    Custom: 0xff,
  };

  static ImageParts = {
    First: 0,
    Second: 1,
  };

  static InfiniteTime = 0xffff;

  static Rotation = {
    Degrees_0: 0,
    Degrees_90: 1,
    Degrees_180: 2,
    Degrees_270: 3,
  };

  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.LedDisplay, store);
  }

  /**
   * Sets an image on the LED display with a specified rotation.
   *
   * @param matrix - The 2D boolean array representing the image to display.
   * @param rotation - The rotation of the image, defaults to 0 degrees.
   */
  setImage(matrix: boolean[][], rotation = LedDisplay.Rotation.Degrees_0) {
    const halves = LedDisplay.splitMatrixToHalvesAndToPayload(matrix);
    this.client.loadCustomImage(LedDisplay.ImageParts.First, this.index, halves.half1);
    this.client.loadCustomImage(LedDisplay.ImageParts.Second, this.index, halves.half2);
    this.client.setDisplayImage(0, this.index, LedDisplay.Image.Custom, rotation, LedDisplay.InfiniteTime);
  }

  /**
   * Sets an image on the LED display with a specified rotation and duration.
   *
   * @param matrix - The 2D boolean array representing the image to display.
   * @param rotation - The rotation of the image, defaults to 0 degrees.
   * @param timeMs - The duration in milliseconds for which the image is displayed.
   * @param onActionDone - The callback function that is called upon completion of the action.
   * @returns An object containing the action ID.
   */
  setImageAction(
    matrix: boolean[][],
    rotation = LedDisplay.Rotation.Degrees_0,
    timeMs: number,
    onActionDone: ({ isError, targetId }: { isError: boolean; targetId: string }) => void
  ) {
    const actionId = this.generateActionOrTriggerId();
    this.subscribeToResponse(actionId, onActionDone);

    const halves = LedDisplay.splitMatrixToHalvesAndToPayload(matrix);

    this.client.loadCustomImage(LedDisplay.ImageParts.First, this.index, halves.half1);
    this.client.loadCustomImage(LedDisplay.ImageParts.Second, this.index, halves.half2);
    this.client.setDisplayImage(actionId, this.index, LedDisplay.Image.Custom, rotation, timeMs);

    return { actionId };
  }

  /**
   * Set an animation on the LED display.
   *
   * @param animation - The animation to display. This can be a number for predefined animations.
   * @param orientation - The orientation of the animation. Defaults to 0 (no rotation).
   */
  setAnimation(animation: number, orientation = LedDisplay.Rotation.Degrees_0) {
    this.client.setAnimation(
      0, // action id
      this.index, // module index
      animation, // animation
      0x01, // repeats
      0x00, // reverse
      orientation, // orientation
      0x00, // num frames
      0x00 // framerate
    );
  }

  /**
   * Sets an animation action on the LED display. The animation can be either a predefined
   * animation represented by a number or a custom animation represented by an array of frames.
   *
   * @param animation - The animation to display. Can be a number for predefined animations or
   *                    an array of boolean matrices for custom animations.
   * @param repeats - The number of times the animation should repeat.
   * @param reverse - Whether the animation should play in reverse.
   * @param orientation - The orientation of the animation.
   * @param framerateMs - The framerate of the animation in milliseconds.
   * @param onActionDone - A callback function that will be called upon completion of the action.
   *                       It receives an object containing `isError` to indicate if an error occurred
   *                       and `targetId` as the identifier of the target.
   */
  setAnimationAction(
    animation: number | Array<boolean[][]>,
    repeats: number,
    reverse: number,
    orientation: number,
    framerateMs: number,
    onActionDone: ({ isError, targetId }: { isError: boolean; targetId: string }) => void
  ) {
    const actionId = this.generateActionOrTriggerId();
    this.subscribeToResponse(actionId, onActionDone);

    if (typeof animation === 'number') {
      this.client.setAnimation(
        actionId,
        this.index,
        animation,
        repeats,
        reverse,
        orientation,
        0x00, // frame count
        0x00 // framerate
      );
    } else {
      for (const frame of animation) {
        const halves = LedDisplay.splitMatrixToHalvesAndToPayload(frame);
        const frameIndex = animation.indexOf(frame);

        this.client.loadCustomAnimationFrame(LedDisplay.ImageParts.First, this.index, frameIndex, halves.half1);
        this.client.loadCustomAnimationFrame(LedDisplay.ImageParts.Second, this.index, frameIndex, halves.half2);
      }

      this.client.setAnimation(
        actionId,
        this.index,
        LedDisplay.Animations.Custom,
        repeats,
        reverse,
        orientation,
        animation.length,
        framerateMs
      );
    }
  }

  /**
   * Displays a scrolling text on the LED display.
   *
   * @param text - The text to display.
   * @param scrollingSpeedMs - The speed of the scrolling in milliseconds.
   * @param rotation - The rotation of the text. See {@link LedDisplay.Rotation} for possible values.
   * @param onActionDone - A callback that will be called when the action is finished. The callback will receive an object with two properties: `isError` and `targetId`. `isError` will be true if the action finished with an error, and `targetId` will be the ID of the target that finished the action.
   * @returns An object with an `actionId` property, which is the ID of the action that was started.
   */
  setTextAction(
    text: string,
    scrollingSpeedMs: number,
    rotation: number,
    onActionDone: ({ isError, targetId }: { isError: boolean; targetId: string }) => void
  ) {
    const actionId = this.generateActionOrTriggerId();
    this.subscribeToResponse(actionId, onActionDone);

    this.client.loadCustomText(this.index, text);
    this.client.setDisplayText(actionId, this.index, rotation, text.length, scrollingSpeedMs);
  }

  /**
   * Rotates the LED display module.
   * @param image - The image to display.
   * @param orientation - The orientation to rotate to.
   */
  rotate(image: number, orientation: number) {
    this.client.setDisplayImage(1, this.index, image, orientation, LedDisplay.InfiniteTime);
  }

  /**
   * Stops the LED display module.
   */
  stop() {
    this.client.displayStop(this.index);
  }

  /**
   * Converts a boolean matrix into a Uint8Array payload suitable for LED display.
   * Each row of the matrix is represented by 2 bytes, where each bit corresponds
   * to the state ('on' or 'off') of a pixel in the row. The first 8 pixels are
   * stored in the most significant byte (MSB), and the remaining pixels are stored
   * in the least significant byte (LSB).
   *
   * @param matrix - A 2D boolean array representing the LED display matrix, where
   * each boolean value indicates whether a pixel is 'on' (true) or 'off' (false).
   * @returns A Uint8Array containing the payload data for the LED display.
   */
  static convertBooleanMatrixToPayload(matrix: boolean[][]) {
    // 2 bytes per each row
    const payload = new Uint8Array(matrix.length * 2);

    // Loop through each row to set the bits for 'on' pixels
    for (const row of matrix) {
      let rowMSB = 0;
      let rowLSB = 0;

      for (let i = 0; i < row.length; i++) {
        if (row[i]) {
          // If the pixel is 'on', set the bit at the correct position
          if (i < 8) {
            // If index is less than 8, it's in the MSB (most significant byte)
            rowMSB |= 1 << (7 - i);
          } else {
            // If index is 8 or more, it's in the LSB (the least significant byte)
            rowLSB |= 1 << (15 - i);
          }
        }
      }

      // Add the two bytes for the current row to the payload
      payload.set([rowMSB, rowLSB], matrix.indexOf(row) * 2);
    }

    return payload;
  }

  /**
   * Splits a given matrix into two halves and converts each half into a payload
   * suitable for the LED display. The matrix is divided into two equal parts,
   * with the first 8 rows forming the first half and the remaining rows forming
   * the second half. Each half is then converted into a Uint8Array payload.
   *
   * @param matrix - A 2D boolean array representing the LED display matrix.
   * @returns An object containing two Uint8Array payloads, one for each half of the matrix.
   */
  static splitMatrixToHalvesAndToPayload(matrix: boolean[][]) {
    return {
      half1: LedDisplay.convertBooleanMatrixToPayload(matrix.slice(0, 8)),
      half2: LedDisplay.convertBooleanMatrixToPayload(matrix.slice(8)),
    };
  }

  /**
   * Converts a given angle in degrees to the corresponding orientation value
   * that can be used with the LED display.
   *
   * @param angle - The angle in degrees to convert.
   *
   * @returns The orientation value corresponding to the given angle.
   *
   * @throws {Error} If the given angle is not one of 0, 90, 180, or 270.
   */
  static convertAngleToOrientation(angle: number) {
    if (angle === 0) {
      return LedDisplay.Rotation.Degrees_0;
    } else if (angle === 90) {
      return LedDisplay.Rotation.Degrees_90;
    } else if (angle === 180) {
      return LedDisplay.Rotation.Degrees_180;
    } else if (angle === 270) {
      return LedDisplay.Rotation.Degrees_270;
    } else {
      throw new Error('Invalid angle. It should be 0, 90, 180, or 270.');
    }
  }
}
