import { WifiSecurityType } from '@webapp/store/types';
import { ModulesCollectionTypes } from '@lib/robo/types';

export const firmwareFolder = 'https://devices.robowunderkind.com/firmware';

export const ALLOWED_ROBO_DEVICE_VERSIONS = ['3', '4'];
export const ALLOWED_BRIDGE_DEVICE_VERSIONS = ['1'];
export const FIRMWARE_MAIN_BLOCK_MQTT_UPDATE_SUPPORTS_FROM = '4.3.5';
export const FIRMWARE_MAIN_BLOCK_MULTIPLE_COMMANDS_SUPPORTS_FROM = '4.3.6';
export const FIRMWARE_OTA_UPDATE_DISABLED_WIFI_SECURITY_TYPES: WifiSecurityType[] = ['WIFI_AUTH_WPA2_ENTERPRISE'];

export const ModulesDisplayOrder: ModulesCollectionTypes[] = [
  ModulesCollectionTypes.System,
  ModulesCollectionTypes.Motor,
  ModulesCollectionTypes.Accelerometer,
  ModulesCollectionTypes.Ultrasonic,
  ModulesCollectionTypes.LedDisplay,
  ModulesCollectionTypes.Button,
  ModulesCollectionTypes.LedMatrix,
  ModulesCollectionTypes.LedRGB,
  ModulesCollectionTypes.Linetracker,
  ModulesCollectionTypes.LightSensor,
  ModulesCollectionTypes.Motion,
];
