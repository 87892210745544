import { BaseModule, IRoboStore } from './base-module';
import { RoboClient } from '@lib/robo/robo-client';
import { ModulesCollectionTypes, HandlerType } from '@lib/robo/types';

// todo Consolidate with sounds map
// Sound values should be stored here. Other modules should use the names
export const sounds = {
  Party: 0x01,
  Car: 0x02,
  Cat: 0x03,
  Clock: 0x04,
  Dog: 0x05,
  Spaceship: 0x06,
  Bell: 0x07,
  Chick: 0x08,
  Owl: 0x09,
  Robot: 0x00,
  Dinosaur: 0x0a,
  Horse: 0x0f,
};

/**
 * System module class that extends BaseModule to control system functionality.
 * Handles battery status, firmware version, and loudness control.
 *
 * @extends BaseModule
 */
export class System extends BaseModule<typeof System> {
  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.System, store);

    this.updateDataState({
      batteryCapacity: 50,
      batteryStatus: 'unknown',
      firmware: '0.0.0',
      loudness: 100,
    });

    this.client.registerHandler(HandlerType.OnFirmwareVersion, version => {
      this.updateDataState({ firmware: `${version.major}.${version.minor}.${version.build}` });
    });

    this.client.registerHandler(HandlerType.OnBattery, battery => {
      this.updateDataState({
        batteryCapacity: battery.capacity,
        batteryStatus: battery.status,
      });
    });

    this.client.registerHandler(HandlerType.OnSoundLoudness, loudness => {
      this.updateDataState({ loudness: loudness.level });
    });
  }

  /**
   * Plays the specified sound.
   * @param {string} sound - The name of the sound to play.
   */
  playSound(soundName: keyof typeof sounds) {
    this.client.playSound(sounds[soundName]);
  }

  /**
   * Sets the play sound action.
   * @param {string} sound - The name of the sound to play.
   * @returns {{ actionId: string }} - The performed action id.
   */
  setPlaySoundAction(
    soundName: keyof typeof sounds,
    onActionDone: ({ isError, targetId }: { isError: boolean; targetId: string }) => void,
  ) {
    const actionIdStart = this.generateActionOrTriggerId();
    const currentSound = sounds[soundName];
    const actionId = actionIdStart + currentSound;

    console.debug('[EXEC] SETTING PLAY SOUND ACTION', { soundName, actionId });

    const unregisterHandler = this.client.registerHandler(
      HandlerType.OnActionOrTriggerResponse,
      ({ isError, targetId }) => {
        console.debug('[EXEC] PLAY SOUND ACTION RESPONSE', { isError, targetId });
        onActionDone({ isError, targetId });
        unregisterHandler();
      },
      `${actionId}`
    );

    this.client.setPlaySoundAction(actionId, currentSound);
    return { actionId };
  }

  /**
   * Sets the running mode of the system.
   * @param {number} [mode=0] - The running mode to set.
   */
  setIsRunning(mode = 0) {
    this.client.setRunCommand(!!mode);
  }

  /**
   * Sets the loudness of the robot's speaker.
   * @param {number} loudness - The loudness value to set, between 0 and 100.
   */
  setLoudness(loudness: number) {
    this.client.setLoudness(loudness);
    this.client.requestLoudness();
  }

  /**
   * Starts the firmware update process.
   */
  startFirmwareUpdate() {
    this.client.startFirmwareUpdate();
  }

  /**
   * Sets the name of the robot.
   * @param {string} name - The name to set.
   */
  setName(name: string) {
    this.client.setRoboName(name);
  }
}
