import { useEffect, useMemo, useState } from 'react';
import { cloneDeep, debounce } from 'lodash';

import { Box, Grid, Tooltip, Typography } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';

import { LedDisplay } from '@lib/robo/modules';
import { ModuleId } from '@lib/robo/types';
import Slider from '@webapp/components/ui/sliders/slider';

import {
  ClearIcon,
  CodeRotateIcon,
  PlayIcon,
  PlusIcon,
  RepeatIcon,
  ReverseIcon,
  SpeedIcon,
} from '@webapp/components/icons';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import IconWithText from '@webapp/components/ui/icon-with-text';
import RoundToggleIconButton from '@webapp/components/ui/buttons/round-toggle-icon-button';

import { LedPixelMatrixComponentPreview } from '@webapp/components/blocks/component/led-pixel-matrix-component-preview';
import { LedPixelMatrixComponent } from '@webapp/components/blocks/component/led-pixel-matrix-component';
import {
  convertToTwoDimensionalArray,
  createInitialMatrixState,
  rotateOneDimensionalMatrix,
} from '@webapp/components/blocks/widgets/led-pixel-display-widget/utils';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import RoundIconButton from '@webapp/components/ui/buttons/round-icon-button';
import { ScrollingContainer } from '@components/ScrollingContainer/scrolling-container';

import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';
import { AbortablePromise } from '@lib/utils/abortable-promise';

import { RoboLaughingAnimation, RoboLooksAnimation, RoboSmileAnimation } from './predefined-animations';

const widgetConfig = {
  maximumAnimationsCount: 10,
  animationFramesCount: 5,
  minRepeatCount: 1,
  maxRepeatCount: 10,
  minAnimationSpeed: 1,
  maxAnimationSpeed: 10,
  updateWidgetDataDelay: 300,
} as const;

const createInitialAnimation = (): CustomAnimation => {
  return {
    isPredefined: false,
    frames: new Array(widgetConfig.animationFramesCount)
      .fill(null)
      .map(() => createInitialMatrixState(LedDisplay.DisplaySize)),
  };
};

const PredefinedAnimations: Array<Animation> = [
  {
    code: LedDisplay.Animations.LookingFace,
    isPredefined: true,
    frames: RoboLooksAnimation,
  },
  {
    code: LedDisplay.Animations.SmilingFace,
    isPredefined: true,
    frames: RoboSmileAnimation,
  },
  {
    code: LedDisplay.Animations.LaughingFace,
    isPredefined: true,
    frames: RoboLaughingAnimation,
  },
];

const mapSpeedToFrameRate = (speed: number) => {
  return 1000 / speed;
};

/**
 * CodeAnimateWidget
 *
 * This widget component is used to display animations on the LED display of the robo.
 *
 * The widget displays a list of predefined animations, as well as a custom animation that can be edited.
 * The user can select the animation to display, as well as the speed at which the animation is displayed.
 *
 * The widget also displays a preview of the current animation.
 */
const CodeAnimateWidget: ExecutableActionWidgetComponent<AnimateWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData: _updateWidgetData } = useCodeEditor();
  const { client: roboClient } = useRobo();

  const updateWidgetData: typeof _updateWidgetData<AnimateWidgetData> = useMemo(
    () =>
      debounce((id, data) => {
        _updateWidgetData<AnimateWidgetData>(id, data);
      }, widgetConfig.updateWidgetDataDelay), // Adjust the debounce delay as needed
    [id]
  );

  const widget = getWidgetById<AnimateWidgetData>(id);
  const widgetData = widget?.data;

  const size = LedDisplay.DisplaySize;
  const [animations, setAnimations] = useState<Array<Animation>>(
    widgetData?.animations ?? CodeAnimateWidget.initialData.animations
  );

  const [activeAnimationIndex, setActiveAnimationIndex] = useState<number>(
    widgetData?.activeAnimationIndex ?? CodeAnimateWidget.initialData.activeAnimationIndex
  );

  // @kobylin Do we still need this?
  const [isPredefinedAnimation, setIsPredefinedAnimation] = useState<boolean>(
    widgetData?.isPredefinedAnimation ?? CodeAnimateWidget.initialData.isPredefinedAnimation
  );

  const [activeAnimationFrameIndex, setActiveAnimationFrameIndex] = useState<number>(0);
  const [rotation, setRotation] = useState<number>(widgetData?.rotation ?? CodeAnimateWidget.initialData.rotation);
  const [repeatCount, setRepeatCount] = useState<number>(
    widgetData?.repeatCount ?? CodeAnimateWidget.initialData.repeatCount
  );
  const [animationSpeed, setAnimationSpeed] = useState<number>(
    widgetData?.animationSpeed ?? CodeAnimateWidget.initialData.animationSpeed
  );
  const [isReversed, setIsReversed] = useState<boolean>(
    widgetData?.isReversed ?? CodeAnimateWidget.initialData.isReversed
  );
  const [isAnimationPlaying, setIsAnimationPlaying] = useState<boolean>(false);

  const { model: roboModel } = useRobo();

  const moduleId = widgetData?.moduleIds[0] as ModuleId;
  const MODULE = roboModel?.modules.ledDisplays[moduleId];

  const handleClear = () => {
    setAnimations(prev => {
      const currentAnimation = cloneDeep(prev[activeAnimationIndex]);
      currentAnimation.frames[activeAnimationFrameIndex] = createInitialMatrixState(size);

      return [...prev.slice(0, activeAnimationIndex), currentAnimation, ...prev.slice(activeAnimationIndex + 1)];
    });

    sendMatrixToLedDisplay(createInitialMatrixState(size), rotation);
  };

  const handleRotate = () => {
    const nextRotation = (rotation + 90) % 360;

    setRotation(nextRotation);
    updateWidgetData(id, { rotation: nextRotation });

    sendMatrixToLedDisplay(allAnimations[activeAnimationIndex].frames[activeAnimationFrameIndex], nextRotation);
  };

  const handleAdd = () => {
    const nextAnimations = [...animations, createInitialAnimation()];
    const nextIndex = nextAnimations.length - 1;

    setAnimations(nextAnimations);
    setActiveAnimationIndex(nextIndex);
    updateWidgetData(id, { activeAnimationIndex: nextIndex });

    sendMatrixToLedDisplay(nextAnimations[nextIndex].frames[activeAnimationFrameIndex], rotation);
  };

  const handleReverse = () => {
    setIsReversed(prev => !prev);
    updateWidgetData(id, { isReversed: !isReversed });
  };

  const canAddMoreDrawings = animations.length < widgetConfig.maximumAnimationsCount;

  const createRemoveHandler = (index: number) => () => {
    const nextAnimations = [...animations.slice(0, index), ...animations.slice(index + 1)];
    const nextIndex = Math.min(index, nextAnimations.length - 1);

    setActiveAnimationIndex(nextIndex);
    setAnimations(nextAnimations);

    sendMatrixToLedDisplay(nextAnimations[nextIndex].frames[activeAnimationFrameIndex], rotation);
  };

  const allAnimations = [...animations, ...PredefinedAnimations];

  /**
   * Handles the "Play" button click.
   *
   * It sets the LED display to play the selected animation.
   * If the animation is predefined, it will be played with the given repeat count and rotation.
   * If the animation is custom, it will be played with the given repeat count, rotation, and frame rate.
   * When the animation is finished, it sets isPlaying to false.
   */
  const handlePlay = () => {
    if (!roboClient || !MODULE) {
      return;
    }

    setIsAnimationPlaying(true);

    const animation = allAnimations[activeAnimationIndex];

    const transactionId = `set-animation-action-${id}`;
    roboClient.beginTransaction(transactionId);

    MODULE.setAnimationAction(
      animation.isPredefined
        ? animation.code
        : animation.frames.map(frame => convertToTwoDimensionalArray(frame, size)),
      repeatCount,
      isReversed ? 1 : 0,
      LedDisplay.convertAngleToOrientation(rotation),
      animation.isPredefined ? 0 : mapSpeedToFrameRate(animationSpeed),
      () => {
        setIsAnimationPlaying(false);
      }
    );

    roboClient.executeTransaction(transactionId);
  };

  const handleStop = () => {
    setIsAnimationPlaying(false);
    roboClient?.setRunCommand(false);
    sendMatrixToLedDisplay(allAnimations[activeAnimationIndex].frames[activeAnimationFrameIndex], rotation);
  };

  /**
   * Sends the given 1D matrix to the LED display at the specified rotation.
   * If the MODULE is not available, does nothing.
   * @param matrix - The 1D boolean matrix to be sent to the LED display.
   * @param rotation - The rotation of the matrix in degrees.
   */
  const sendMatrixToLedDisplay = (matrix: Array<boolean>, rotation: number) => {
    if (!roboClient || !MODULE) {
      return;
    }
    const twoDimMatrix = convertToTwoDimensionalArray(rotateOneDimensionalMatrix(matrix, size, rotation), size);

    // unique transaction id to avoid split transactions for different modules
    const transactionId = `set-image-${id}`;
    roboClient.beginTransaction(transactionId);
    MODULE.setImage(twoDimMatrix);
    roboClient.executeTransaction(transactionId);
  };

  // Effects
  useEffect(() => {
    updateWidgetData(id, { animations: animations });
  }, [id, animations]);

  useEffect(() => {
    return () => {
      roboClient?.setRunCommand(false);
    };
  }, [roboClient]);

  // this hook is executed when component is mounted and when activeAnimationFrameIndex changes
  useEffect(() => {
    sendMatrixToLedDisplay(allAnimations[activeAnimationIndex].frames[activeAnimationFrameIndex], rotation);
  }, [activeAnimationFrameIndex]);

  return (
    <Box
      sx={{
        width: '600px',
      }}
    >
      <Grid container spacing={0} sx={{ paddingLeft: '0px' }}>
        <Grid
          item
          xs={9}
          sx={{
            paddingTop: '10px',
            paddingLeft: '20px',
            paddingRight: '24px',
          }}
        >
          {/* PREVIEW */}
          <Grid item xs={12} sx={{ marginBottom: '12px' }}>
            <ScrollingContainer
              sx={{ width: '100%', border: 'none', background: 'none', boxShadow: 'none' }}
              contentSx={{
                padding: '12px 35px',
                gap: '8px',
                background: '#fafafa',
              }}
              leftArrowsSx={{
                background: '#fafafa',
                border: 'none',
                height: 'calc(100% - 14px)',
                '&:hover': {
                  background: '#fafafa',
                },
              }}
              rightArrowsSx={{
                background: '#fafafa',
                border: 'none',
                height: 'calc(100% - 14px)',
                '&:hover': {
                  background: '#fafafa',
                },
              }}
            >
              {allAnimations.map((animation, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-3px',
                      left: '3px',
                      border: '1px solid #5A418B',
                      width: '100%',
                      height: '100%',
                      borderRadius: '2px',
                    }}
                  ></Box>

                  <LedPixelMatrixComponentPreview
                    sx={{
                      border: '1px solid #5A418B',
                      padding: '2px',
                    }}
                    size={size}
                    theming="yellow"
                    matrix={animation.frames[0]}
                    selected={activeAnimationIndex === index}
                    disabled={isAnimationPlaying}
                    onClick={() => {
                      setActiveAnimationIndex(index);
                      setActiveAnimationFrameIndex(0);
                      setIsPredefinedAnimation(animation.isPredefined);
                      updateWidgetData(id, {
                        activeAnimationIndex: index,
                        isPredefinedAnimation: animation.isPredefined,
                      });
                      sendMatrixToLedDisplay(animation.frames[0], rotation);
                    }}
                    onRemove={
                      animations.length === 1 || animation.isPredefined ? undefined : createRemoveHandler(index)
                    }
                  />
                </Box>
              ))}
            </ScrollingContainer>
          </Grid>
          {/* Drawing canvas */}
          <Grid
            item
            container
            xs={12}
            spacing={0}
            sx={{
              height: '300px',
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                height: '100%',
              }}
            >
              {allAnimations[activeAnimationIndex].frames.map((animation, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexGrow: '1',
                    padding: '0 10px',
                  }}
                >
                  <Typography variant="x-small-bold" color="#5A418B">
                    {index + 1}
                  </Typography>

                  <LedPixelMatrixComponentPreview
                    sx={{
                      border: '1px solid #5A418B',
                      padding: '2px',
                      width: 'initial',
                      minWidth: 'initial',
                      minHeight: 'initial',
                      height: '100%',
                      aspectRatio: '1 / 1',
                    }}
                    size={size}
                    theming="yellow"
                    matrix={animation}
                    selected={activeAnimationFrameIndex === index}
                    disabled={isAnimationPlaying}
                    onClick={() => {
                      setActiveAnimationFrameIndex(index);
                      sendMatrixToLedDisplay(animation, rotation);
                    }}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={9}>
              <LedPixelMatrixComponent
                size={size}
                sx={{
                  // this fix is needed for Safari, oherwise it calculates height in this
                  // particular layout not properly
                  height: '100%',
                  width: 'fit-content',
                }}
                matrix={allAnimations[activeAnimationIndex].frames[activeAnimationFrameIndex]}
                disabled={allAnimations[activeAnimationIndex].isPredefined || isAnimationPlaying}
                theming="yellow"
                onMatrixChanged={matrix => {
                  setAnimations(prev => {
                    const currentAnimation = cloneDeep(prev[activeAnimationIndex]);
                    currentAnimation.frames[activeAnimationFrameIndex] = matrix;

                    return [
                      ...prev.slice(0, activeAnimationIndex),
                      currentAnimation,
                      ...prev.slice(activeAnimationIndex + 1),
                    ];
                  });
                }}
                onDragEnd={matrix => {
                  sendMatrixToLedDisplay(matrix, rotation);
                }}
              />
            </Grid>
          </Grid>

          {/* Play controls */}
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '16px 0px 0px 30px',
            }}
          >
            <Tooltip title={canAddMoreDrawings ? '' : `Maximum is ${widgetConfig.maximumAnimationsCount} animations`}>
              <span>
                <RoundIconButton
                  icon={<PlusIcon sx={{ width: 20, height: 20 }} htmlColor="#5A418B" />}
                  disabled={!canAddMoreDrawings || isAnimationPlaying}
                  onClick={handleAdd}
                  text={
                    <Typography variant="x-tiny-bold" color="#5A418B">
                      New
                    </Typography>
                  }
                  mainColor="#5A418B"
                  secondaryColor="#E9E9E9"
                  tertiaryColor="#FFFFFF"
                />
              </span>
            </Tooltip>

            <RoundIconButton
              icon={<ClearIcon sx={{ width: 28, height: 28 }} />}
              disabled={isAnimationPlaying || allAnimations[activeAnimationIndex].isPredefined}
              onClick={handleClear}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Clear
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
            />

            <RoundIconButton
              icon={<CodeRotateIcon sx={{ width: 25, height: 25 }} />}
              disabled={isAnimationPlaying}
              onClick={handleRotate}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Rotate
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
            />

            <RoundToggleIconButton
              icon={<ReverseIcon sx={{ width: 42, height: 42 }} />}
              onChange={handleReverse}
              disabled={isAnimationPlaying || allAnimations[activeAnimationIndex].isPredefined}
              value={isReversed}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Reverse
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
            />

            {isAnimationPlaying ? (
              <RoundIconButton
                icon={<StopIcon sx={{ width: 25, height: 25 }} htmlColor="#5A418B" />}
                disabled={!MODULE}
                onClick={handleStop}
                text={
                  <Typography variant="x-tiny-bold" color="#5A418B">
                    Stop
                  </Typography>
                }
                mainColor="#5A418B"
                secondaryColor="#E9E9E9"
                tertiaryColor="#FFFFFF"
              />
            ) : (
              <RoundIconButton
                icon={<PlayIcon sx={{ width: 15, height: 15 }} htmlColor="#5A418B" />}
                disabled={!MODULE}
                onClick={handlePlay}
                text={
                  <Typography variant="x-tiny-bold" color="#5A418B">
                    Play
                  </Typography>
                }
                mainColor="#5A418B"
                secondaryColor="#E9E9E9"
                tertiaryColor="#FFFFFF"
              />
            )}
          </Grid>
        </Grid>

        {/* Right panel with sliders */}
        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            gap: '16px',
            boxShadow: '0px 2px 19px rgba(0, 0, 0, 0.30)',
            clipPath: 'inset(0px 0px 0px -20px)',
            marginTop: '-10px',
            marginBottom: '-10px',
            marginRight: '-20px',
            paddingTop: '20px',
            paddingBottom: '40px',
            paddingLeft: '22px',
            paddingRight: '24px',
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconWithText
              sx={{
                marginBottom: '42px',
              }}
              icon={<RepeatIcon sx={{ fontSize: 'inherit', width: 30, height: 30 }} />}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Repeat
                </Typography>
              }
            />
            <Slider
              value={repeatCount}
              valueLabelDisplay="on"
              maxAsInfinite={false}
              minAsInfinite={false}
              max={widgetConfig.maxRepeatCount}
              min={widgetConfig.minRepeatCount}
              step={1}
              disabled={isAnimationPlaying}
              onChange={(_, value) => {
                if (typeof value !== 'number') {
                  return;
                }
                setRepeatCount(value);
                updateWidgetData(id, { repeatCount: value });
              }}
              orientation="vertical"
              sx={{ height: '100%', width: '30px' }}
              mainColor="#FEC84B"
              railColor="#E9E9E9"
              labelColor="#5A418B"
              appearance="default"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconWithText
              sx={{
                marginBottom: '42px',
              }}
              icon={<SpeedIcon sx={{ fontSize: 'inherit', width: 30, height: 30 }} />}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Speed
                </Typography>
              }
            />
            <Slider
              value={animationSpeed}
              valueLabelDisplay="on"
              maxAsInfinite={false}
              minAsInfinite={false}
              max={widgetConfig.maxAnimationSpeed}
              min={widgetConfig.minAnimationSpeed}
              step={1}
              disabled={allAnimations[activeAnimationIndex].isPredefined || isAnimationPlaying}
              onChange={(_, value) => {
                if (typeof value !== 'number') {
                  return;
                }
                setAnimationSpeed(value);
                updateWidgetData(id, { animationSpeed: value });
              }}
              orientation="vertical"
              sx={{ height: '100%', width: '30px' }}
              mainColor="#FEC84B"
              railColor="#E9E9E9"
              labelColor="#5A418B"
              appearance="default"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

CodeAnimateWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, async (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const moduleId = widgetData.moduleIds[0] as ModuleId;
    const { animations, repeatCount, animationSpeed, isReversed, activeAnimationIndex, rotation } = widgetData;

    const allAnimations = [...animations, ...PredefinedAnimations];

    if (!moduleId || !roboModel) {
      throw new Error('Module or RoboModel not found');
    }

    if (animations.length === 0) {
      throw new Error('No animations available');
    }
    const MODULE = roboModel.modules.ledDisplays[moduleId];
    if (!MODULE) {
      throw new Error('Module not found');
    }

    const animation = allAnimations[activeAnimationIndex];
    if (!animation) {
      throw new Error('Animation not found');
    }

    const handleResult = ({ isError }: { isError: boolean }) => {
      if (isError) {
        reject(new Error('Error setting motor action'));
      } else {
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }
    };

    MODULE.setAnimationAction(
      animation.isPredefined
        ? animation.code
        : animation.frames.map(frame => convertToTwoDimensionalArray(frame, LedDisplay.DisplaySize)),
      repeatCount,
      isReversed ? 1 : 0,
      LedDisplay.convertAngleToOrientation(rotation),
      animation.isPredefined ? 0 : mapSpeedToFrameRate(animationSpeed),
      handleResult
    );
  });
};

type PredefinedAnimation = {
  isPredefined: true;
  frames: Array<Array<boolean>>;
  code: number;
};

type CustomAnimation = {
  isPredefined: false;
  frames: Array<Array<boolean>>;
  code?: never;
};

type Animation = PredefinedAnimation | CustomAnimation;

type AnimateWidgetData = {
  animations: Array<Animation>;
  repeatCount: number;
  animationSpeed: number;
  isReversed: boolean;
  activeAnimationIndex: number;
  isPredefinedAnimation: boolean;
  rotation: number;
};

CodeAnimateWidget.initialData = {
  animations: [createInitialAnimation()],
  repeatCount: 1,
  animationSpeed: 1,
  isReversed: false,
  activeAnimationIndex: 0,
  isPredefinedAnimation: false,
  rotation: 0,
};

export default CodeAnimateWidget;
