import { useMemo } from 'react';

import { useSelector } from '@store/configureStore';
import { selectAuth } from '@store/slices/auth.slice';

const useAuthHook = () => {
  const { jwt, user, isLoggedIn, loginMethod } = useSelector(selectAuth);

  return useMemo(
    () => ({
      jwt,
      user,
      isLoggedIn,
      loginMethod,
    }),
    [jwt, user, isLoggedIn, loginMethod]
  );
};

export default useAuthHook;
