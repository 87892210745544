import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { Box, Grid, Typography } from '@mui/material';
import { SliderOwnProps } from '@mui/material/Slider/Slider';

import { ModuleId } from '@lib/robo/types';
import { LedDisplay } from '@lib/robo/modules';
import Slider from '@webapp/components/ui/sliders/slider';
import { AbortablePromise } from '@lib/utils/abortable-promise';
import { CodeRotateIcon, PlayIcon, SpeedIcon } from '@webapp/components/icons';

import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import IconWithText from '@webapp/components/ui/icon-with-text';
import RoundIconButton from '@webapp/components/ui/buttons/round-icon-button';
import { RestrictedTextArea } from '@webapp/components/blocks/component/restricted-text-area';

const widgetConfig = {
  minSpeed: 1,
  maxSpeed: 10,
  updateWidgetDataDelay: 300,
} as const;

const convertSpeedToRateMs = (speed: number) => {
  return (widgetConfig.maxSpeed - speed) * 50;
};

const CodeTextWidget: ExecutableActionWidgetComponent<TextWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData: _updateWidgetData } = useCodeEditor();

  const { client: roboClient } = useRobo();

  const updateWidgetData: typeof _updateWidgetData<TextWidgetData> = useMemo(
    () =>
      debounce((id, data) => {
        _updateWidgetData<TextWidgetData>(id, data);
      }, widgetConfig.updateWidgetDataDelay), // Adjust the debounce delay as needed
    [id]
  );

  const widget = getWidgetById<TextWidgetData>(id);
  const widgetData = widget?.data;

  const [text, setText] = useState<string>(widgetData?.text ?? CodeTextWidget.initialData.text);
  const [speed, setSpeed] = useState<number>(widgetData?.speed ?? CodeTextWidget.initialData.speed);
  const [rotation, setRotation] = useState<number>(widgetData?.rotation ?? CodeTextWidget.initialData.rotation);

  const { model: roboModel } = useRobo();

  const moduleId = widgetData?.moduleIds[0] as ModuleId;
  const MODULE = roboModel?.modules.ledDisplays[moduleId];

  const handleRotate = () => {
    const nextRotation = (rotation + 90) % 360;
    setRotation(nextRotation);
    updateWidgetData(id, { rotation: nextRotation });
    // we don't debounce this because it need to be immediate
    sendTextToLedDisplay(text, speed, nextRotation);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    setText(newText);
    updateWidgetData(id, { text: newText });
    sendTextToLedDisplayDebounced(newText, speed, rotation);
  };

  const handleSpeedChange: SliderOwnProps['onChange'] = (_, newValue) => {
    if (typeof newValue !== 'number') {
      return;
    }
    setSpeed(newValue);
    updateWidgetData(id, { speed: newValue });
    sendTextToLedDisplayDebounced(text, newValue, rotation);
  };

  const sendTextToLedDisplay = useCallback(
    (text: string, speed: number, rotation: number) => {
      if (!MODULE || !roboClient) {
        return;
      }
      roboClient.setRunCommand(false); // stop previous action

      const transactionId = `set-text-action-${id}`;
      roboClient.beginTransaction(transactionId);

      MODULE.setTextAction(
        text,
        convertSpeedToRateMs(speed),
        LedDisplay.convertAngleToOrientation(rotation),
        ({ isError }) => {
          if (isError) {
            console.error('Error setting text action');
          }
        }
      );

      roboClient.executeTransaction(transactionId);
    },
    [MODULE, roboClient]
  );

  const sendTextToLedDisplayDebounced = useMemo(() => {
    return debounce(sendTextToLedDisplay, 1000);
  }, [sendTextToLedDisplay]);

  const handlePlay = () => {
    sendTextToLedDisplay(text, speed, rotation);
  };

  // send matrix to led display on mount
  useEffect(() => {
    sendTextToLedDisplay(text, speed, rotation);

    return () => {
      roboClient?.setRunCommand(false);
    };
  }, []);

  return (
    <Box
      sx={{
        padding: '10px 0px 10px 10px',
        width: '400px',
      }}
    >
      <Grid container spacing={0}>
        <Grid item container xs={9} spacing={1}>
          <Grid item xs={12}>
            <RestrictedTextArea
              value={text}
              onChange={handleTextChange}
              maxLength={LedDisplay.TextMaxLength}
              sx={{ height: '230px' }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <RoundIconButton
              icon={<PlayIcon sx={{ width: 15, height: 15 }} htmlColor="#5A418B" />}
              disabled={!MODULE}
              onClick={handlePlay}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Play
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
            />

            <RoundIconButton
              icon={<CodeRotateIcon sx={{ width: 25, height: 25 }} />}
              onClick={handleRotate}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Rotate
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            marginBottom: '20px',
          }}
        >
          <IconWithText
            icon={<SpeedIcon sx={{ fontSize: 'inherit', width: 30, height: 30 }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B" sx={{ textAlign: 'center' }}>
                Scrolling speed
              </Typography>
            }
          />
          <Slider
            value={speed}
            valueLabelDisplay="on"
            maxAsInfinite={false}
            minAsInfinite={false}
            max={widgetConfig.maxSpeed}
            min={widgetConfig.minSpeed}
            step={1}
            onChange={handleSpeedChange}
            orientation="vertical"
            sx={{ height: '100%', width: '30px' }}
            mainColor="#FEC84B"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="default"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

CodeTextWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, async (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const moduleId = widgetData.moduleIds[0] as ModuleId;
    const { text, speed, rotation } = widgetData;

    if (!moduleId || !roboModel) {
      throw new Error('Module or RoboModel not found');
    }

    const LED_DISPLAY = roboModel.modules.ledDisplays[moduleId];

    LED_DISPLAY.setTextAction(
      text,
      convertSpeedToRateMs(speed),
      LedDisplay.convertAngleToOrientation(rotation),
      ({ isError }) => {
        if (isError) {
          reject(new Error('Error setting text action'));
        } else {
          resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
        }
      }
    );
  });
};

type TextWidgetData = {
  text: string;
  speed: number;
  rotation: number;
};

CodeTextWidget.initialData = {
  text: '',
  speed: 0,
  rotation: 0,
};

export default CodeTextWidget;
