import { WidgetId, WidgetEntity, WidgetData, CodeEditorTrigger, CodeConfig } from '@webapp/store/types';
import { RoboModel } from '@lib/robo/robo-model';

export enum WidgetExecutionType {
  Action = 'action',
  Trigger = 'trigger',
}

interface WidgetExecutionResult {
  widgetId: WidgetId;
  resolved: boolean;
  type: WidgetExecutionType;
  passed?: (triggers: CodeEditorTrigger[]) => void;
}

export interface ActionWidgetExecutionResult extends WidgetExecutionResult {
  type: WidgetExecutionType.Action;
}

export interface TriggerWidgetExecutionResult extends WidgetExecutionResult {
  type: WidgetExecutionType.Trigger;
  persistenceDuration?: number; // Optional field to specify the duration for which the trigger's resolution result is persisted
}

type WidgetExecuteFunction<T extends WidgetExecutionType, D> = (params: {
  widgetId: WidgetId;
  roboModel: RoboModel;
  getWidgetById: GetWidgetByIdType<D>;
  updateWidgetData: UpdateWidgetDataType<D>;
  getProjectConfig: GetProjectConfigType;
  signal: AbortSignal;
}) =>
  | Promise<T extends WidgetExecutionType.Action ? ActionWidgetExecutionResult : TriggerWidgetExecutionResult>
  | undefined;

type WidgetResetFunction<D> = {
  (params: {
    roboModel: RoboModel;
    widgetId: WidgetId;
    getWidgetById: GetWidgetByIdType<D>;
    updateWidgetData: UpdateWidgetDataType<D>;
    getProjectConfig: GetProjectConfigType;
  }): Promise<void>;
};

// Utility type to infer the data type based on the component name
type ExecutableWidgetStatic<T extends WidgetExecutionType, D> = {
  execute: WidgetExecuteFunction<T, D>;
  reset?: WidgetResetFunction<D>;
  initialData: D;
  initialProps?: Record<string, unknown>;
};

export type ExecutableWidgetComponent<T extends WidgetExecutionType, D> = React.FC<{ id: WidgetId }> &
  ExecutableWidgetStatic<T, D>;

// Specify the data type explicitly for each widget type
export type ExecutableActionWidgetComponent<D = unknown> = ExecutableWidgetComponent<WidgetExecutionType.Action, D>;
export type ExecutableTriggerWidgetComponent<D = unknown> = ExecutableWidgetComponent<WidgetExecutionType.Trigger, D>;

// App specific definitions
export type GetWidgetByIdType<D = unknown> = (widgetId: WidgetId) => WidgetEntity<D> | undefined;
export type UpdateWidgetDataType<D = unknown> = (widgetId: WidgetId, data: Partial<WidgetData<D>>) => void;
export type GetProjectConfigType = () => CodeConfig;
