enum Commands {
  CMD_ROBOID = 0x00, // DEPRECATED
  CMD_CONFIGURATION = 0x01, // out, in
  CMD_FWUPDATE = 0x02, // out
  CMD_FWUPDATE_PROCESS = 0x03, // out, in
  CMD_START_OTA_SERVER = 0x04, // DEPRECATED
  CMD_CURRENT_TIME = 0x05, // DEPRECATED
  CMD_BT_NAME = 0x06, // out
  CMD_FWVERSION = 0x07, // out, in
  CMD_START_LT_TEST = 0x08, // DEPRECATED
  CMD_WIFI_SCAN = 0x0a, // out, in
  CMD_FWDOWNLOAD_ACTIONS = 0x0b, // DEPRECATED
  CMD_FWDOWNLOAD_TRIGGERS = 0x0c, // DEPRECATED
  CMD_FWDOWNLOAD_TRANSITIONS = 0x0d, // DEPRECATED
  CMD_FWDOWNLOAD_ERASE = 0x0e, // DEPRECATED
  CMD_BAT_STATE = 0x10, // out, in
  CMD_LOW_BAT_ALLERT = 0x11, // in
  CMD_SOUND_LOUDNESS = 0x12, // out
  CMD_APP_TYPE = 0x20, // DEPRECATED
  CMD_RUN_STOP = 0x30, // out, in
  CMD_CURRENT_STATE = 0x31, // DEPRECATED
  CMD_NETSTATUS = 0x40, // DEPRECATED
  CMD_SET_WIFI_AP = 0x41, // DEPRECATED
  CMD_SEARCH_WIFI = 0x42, // DEPRECATED
  CMD_NETMANAGER = 0x43, // DEPRECATED
  CMD_NETINFO = 0x44, // DEPRECATED
  CMD_SET_WIFI_SSID = 0x45, // out
  CMD_SET_WIFI_PSK = 0x46, // out
  CMD_FILE_HEADER = 0x47, // DEPRECATED
  CMD_FILE_PAYLOAD = 0x48, // DEPRECATED
  CMD_MODULES_FWUPDATE = 0x49, // out, in
  CMD_SERVO_TORQUE = 0x4a, // out, in
  CMD_HINGE_TORQUE = 0x4b, // out, in
  CMD_CLAW_TORQUE = 0x4c, // out, in
  CMD_HINGE_POT_RD = 0x4d, // out, in
  CMD_SERVO_POT_RD = 0x4e, // out, in
  CMD_CLAW_POT_RD = 0x4f, // out, in
  CMD_MOTOR_SPEED = 0x50, // out, in
  CMD_SERVO_POSITION = 0x51, // out, in
  CMD_MATRIX = 0x52, // out, in
  CMD_RGB = 0x53, // out, in
  CMD_DISPLAY_ANIMATION = 0x54, // out, in
  CMD_DISPLAY_LOAD_ANIMATION = 0x55, // out
  CMD_DISPLAY_LOAD_TEXT = 0x56, // out
  CMD_DISPLAY_CUSTOM_IMAGE = 0x57, // out
  CMD_DISPLAY_IMAGE = 0x58, // out
  CMD_DISPLAY_SET_TEXT = 0x59, // out, in
  CMD_DISPLAY_STOP = 0x5a, // out, in
  CMD_MOTOR_ANGLE = 0x5b, // out, in
  CMD_CLAW_PROX_RD = 0x5c, // out, in
  CMD_AVAILABLE_SOUNDS = 0x60, // out, in
  CMD_PLAY_SOUND = 0x61, // out
  CMD_DELETE_RECORDED_SOUND = 0x62, // DEPRECATED
  CMD_AVAILABLE_RECORDED_SOUNDS = 0x64, // DEPRECATED
  CMD_PLAY_RECORDED_SOUND = 0x65, // DEPRECATED
  CMD_ZERO_SERVO = 0x66, // out
  CMD_AVAILABLE_IR = 0x70, // DEPRECATED
  CMD_PLAY_IR = 0x71, // DEPRECATED
  CMD_GET_IR = 0x72, // DEPRECATED
  CMD_SET_IR_TRIGGER = 0x73, // DEPRECATED
  CMD_LIGHT_LEVEL = 0x80, // out, in
  CMD_SOUND_LEVEL = 0x81, // out, in
  CMD_TEMP_LEVEL = 0x82, // DEPRECATED
  CMD_MOTION_DET = 0x83, // out, in
  CMD_GET_DISTANCE = 0x84, // out, in
  CMD_GET_BUTTON = 0x85, // out, in
  CMD_GET_LINETRACKER = 0x86, // out, in
  CMD_ACCELEROMETER_STATE = 0x87, // out
  CMD_CALIB_LINETRACKER = 0x88, // DEPRECATED
  CMD_GET_ACC = 0x89, // out, in
  CMD_GET_GYR = 0x8a, // out, in
  CMD_BATCH_SENSORS = 0x90, // out, in
  CMD_SET_TUNE = 0x92, // out
  CMD_UPLOAD_CUSTOM_TUNE = 0x93, // out
  CMD_PLAY_NOTE = 0x94, // out
  CMD_SET_MOTOR_ACTION = 0xa0, // out
  CMD_SET_SERVO_ACTION = 0xa1, // out
  CMD_SET_RGB_ACTION = 0xa2, // out
  CMD_SET_MATRIX_ACTION = 0xa3, // out
  CMD_SET_PLAY_SOUND_ACTION = 0xa4, // out
  CMD_SET_PLAY_REC_SOUND_ACTION = 0xa5, // DEPRECATED
  CMD_SET_DRIVE_ACTION = 0xa6, // out
  CMD_SET_MATRIX_STRING_ACTION_0 = 0xa7, // DEPRECATED
  CMD_SET_MATRIX_STRING_ACTION_1 = 0xa8, // DEPRECATED
  CMD_SET_LINETRACKER_ACTION = 0xa9, // out
  CMD_SET_GYRO_TURN_ACTION = 0xaa, // out
  CMD_SET_STEER_ACTION = 0xab, // out
  CMD_SET_TURN_ACTION = 0xac, // out
  CMD_SERVO_POS_ACTION = 0xad, // DEPRECATED
  CMD_HINGE_POS_ACTION = 0xae, // DEPRECATED
  CMD_CLAW_ACTION = 0xaf, // DEPRECATED
  CMD_SET_DISTANCE_TRIGGER = 0xb0, // out
  CMD_SET_BUTTON_TRIGGER = 0xb1, // out
  CMD_SET_LIGHT_TRIGGER = 0xb2, // out
  CMD_SET_MOTION_TRIGGER = 0xb3, // out
  CMD_SET_SOUND_TRIGGER = 0xb4, // DEPRECATED
  CMD_SET_LINETRACKER_TRIGGER = 0xb5, // out
  CMD_SET_ACCELEROMETER_TRIGGER = 0xb6, // out
  CMD_SET_CLAW_TRIGGER = 0xb7, // DEPRECATED
  CMD_ACTION_OR_TRIGGER_RESPONSE = 0xc0, // in
  CMD_MULTIPLE_COMMAND = 0xf0, // multiple commands
  MAX_CMDS = 0xc1,
}

export default Commands;
